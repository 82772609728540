<template>
  <div class="d-flex align-center justify-center">
    <v-row class="h-100 justify-center align-center" :id="wrapId">
      <v-col class="align-center justify-center" lg="5" sm="8">
        <v-row class="space-block" :id="textId">
          <v-col>
            <br />

            <div class="scroller-box-window" id="scroller-box-window">
              <div
                class="scroller-box d-flex align-center justify-center"
                id="sign-up-box"
              >
                <div>
                  <v-row>
                    <h1 id="previousTitle" class="text-center super">
                      Get In Touch <span class="nowrap"> </span></h1
                  ></v-row>
                  <v-row class="h2">
                    <v-col class="text-center">
                      <a class="no-ul" href="mailto:founders@ulo.world"
                        ><v-icon class="curzr-hover" color="white" large
                          >mdi-email</v-icon
                        >
                      </a>
                    </v-col>
                    <v-col class="text-center">
                      <a
                        href="https://twitter.com/ulo_world"
                        target="_blank"
                        class="curzr-hover no-ul"
                      >
                        <v-icon class="curzr-hover" color="white" large
                          >mdi-twitter</v-icon
                        >
                      </a>
                    </v-col>
                    <v-col class="text-center">
                      <a
                        href="https://www.linkedin.com/in/danivandesande/"
                        target="_blank"
                        class="curzr-hover no-ul"
                      >
                        <v-icon class="curzr-hover" color="white" large
                          >mdi-linkedin</v-icon
                        >
                      </a>
                    </v-col>
                  </v-row>
                </div>

                <div class="copyright text-center">
                  © ULO INC 2022 All rights reserved
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "PreviousWorkSection",

  data: () => ({
    numImages: 7,
    numImagesLoaded: 0,
    sec: "prevWork",
    extraShapes: [],
    img1Loaded: false,
    img2Loaded: false,
    textRectLoaded: false,
    details: {
      circle: false,
      strokeWidth: 10,
      shapeWidth: 130,
      shapeHeight: 130,
      gradStroke: true,
      strokeColor: "#000",
      gapBetweenShapes: 150,
      offsetMultiplier: 0.5,
      gradFill: true,
      fill: false,
      gradColors: [
        [221, 116, 202],
        [157, 177, 255],
      ],
    },
    textRect: {
      top: 0,
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      width: 0,
      x: 0,
      y: 0,
    },
  }),
  mounted() {
    this.getTextRect();
  },
  computed: {
    textId() {
      return "textSec-" + this.sec;
    },
    wrapId() {
      return "wrapSec-" + this.sec;
    },
    // imagesLoaded() {
    //     return this.numImagesLoaded >= this.numImages;
    // },
    isMounted() {
      return this.textRectLoaded;
    },
  },

  methods: {
    // loadImage() {
    //     this.numImagesLoaded++;
    // },
    getTextRect() {
      let dr = document
        .querySelector("#" + this.textId)
        ?.getBoundingClientRect();
      if (dr) {
        this.textRect = {
          top: dr.top,
          bottom: dr.bottom,
          height: dr.height,
          left: dr.left,
          right: dr.right,
          width: dr.width,
          x: dr.x,
          y: dr.y,
        };
      }
      this.textRectLoaded = true;
    },
    adjustWrapperMargin(margin) {
      const textWrapper = document.querySelector("#" + this.wrapId);
      textWrapper.style.marginLeft = margin;
      textWrapper.style.marginRight = margin;
    },

    adjustLogo(logoAdjustments) {
      const logo = document.querySelector("#logoImg");
      logo.style.maxWidth = logoAdjustments.mw;
      logo.style.marginBottom = logoAdjustments.mb;
    },
    // img1Load() {
    //     this.img1Loaded = true;
    // },
    // img2Load() {
    //     this.img2Loaded = true;
    // }
  },
};
</script>
