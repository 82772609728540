<template>
  <div class="d-flex align-center justify-center">
    <div id="cube-services" class="cube-wrapper">
      <CubeCanvas
        v-if="isMounted"
        @adjustLogo="adjustLogo"
        @adjustWrapperMargin="adjustWrapperMargin"
        :textRect="textRect"
        :objDetails="details"
        :sec="sec"
      />
    </div>
    <v-row class="h-100 justify-center align-center" :id="wrapId">
      <v-col class="" lg="12" :id="textId">
        <div
          class="space-block text-center w-100 h1 d-flex flex-column align-center justify-center"
        >
          <h1 id="services-text" class="h1 super services-text">
            What we <span class="nowrap">do</span>
          </h1>
          <h1 id="services-text-2" class="h1 super services-text">
            Who we <span class="nowrap">do it with</span>
          </h1>
          <div
            class="carousel-window d-flex flex-column align-center"
            id="carousel-window"
          >
            <h1 class="carousel-text super" id="carousel-text-1">
              AI Activations & Use Cases<span class="accent-text"
                >&nbsp; - &nbsp;
              </span>
              Digital Art Curation & Installation
              <span class="accent-text">&nbsp; - &nbsp; </span> Next Gen
              Community Building & Loyalty
              <span class="accent-text">&nbsp; - &nbsp; </span> Immersive
              Experiences (AR Filters, experiential activations)
            </h1>

            <div class="carousel-text super" id="carousel-text-2">
              <img src="../assets/img/nars.png" alt="" class="carousel-image" />

              <img
                src="../assets/img/superrare.png"
                alt=""
                class="carousel-image"
              />
              <img
                src="../assets/img/shiseido.avif"
                alt=""
                class="carousel-image"
              />
              <img
                src="../assets/img/chromehearts.png"
                alt=""
                class="carousel-image"
              />
              <img src="../assets/img/mars.png" alt="" class="carousel-image" />

              <img
                src="../assets/img/trippylabs.png"
                alt=""
                class="carousel-image"
              />
              <img
                src="../assets/img/29rooms.png"
                alt=""
                class="carousel-image"
              />
              <img src="../assets/img/snap.png" alt="" class="carousel-image" />
              <img
                src="../assets/img/techstars.png"
                alt=""
                class="carousel-image"
              />
              <img
                src="../assets/img/outside.png"
                alt=""
                class="carousel-image"
              />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CubeCanvas from "./CubeCanvas.vue";
// import TagCloud from 'TagCloud';
export default {
  name: "ServicesSection",

  components: {
    CubeCanvas,
  },

  data: () => ({
    isMounted: false,
    sec: "services",
    details: {
      circle: false,
      strokeWidth: 4,
      shapeWidth: 1,
      shapeHeight: 1,
      gradStroke: false,
      strokeColor: "#fff",
      gapBetweenShapes: 125,
      offsetMultiplier: 0.2,
      gradFill: false,
      fill: false,
    },
    textRect: {
      top: 0,
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      width: 0,
      x: 0,
      y: 0,
    },
  }),
  mounted() {
    this.getTextRect();
    this.isMounted = true;
  },
  computed: {
    textId() {
      return "textSec-" + this.sec;
    },
    wrapId() {
      return "wrapSec-" + this.sec;
    },
    mobileMultiplier() {
      return Math.max(window.innerWidth, window.innerHeight) / 2560;
    },
  },
  methods: {
    getTextRect() {
      let dr = document
        .querySelector("#" + this.textId)
        ?.getBoundingClientRect();
      if (dr) {
        this.textRect = {
          top: dr.top,
          bottom: dr.bottom,
          height: dr.height,
          left: dr.left,
          right: dr.right,
          width: dr.width,
          x: dr.x,
          y: dr.y,
        };
      }
    },

    adjustWrapperMargin(margin) {
      const textWrapper = document.querySelector("#" + this.wrapId);
      textWrapper.style.marginLeft = margin;
      textWrapper.style.marginRight = margin;
    },

    adjustLogo(logoAdjustments) {
      const logo = document.querySelector("#logoImg");
      logo.style.maxWidth = logoAdjustments.mw;
      logo.style.marginBottom = logoAdjustments.mb;
    },
  },
};
</script>
<style scoped>
.carousel-image {
  height: 2em;
  margin: 0.8em 1em 0 1em;
}

.carousel-image.smaller {
  padding: 0.5em;
}

.carousel-text {
  height: 5em;
}
</style>
