<template>
  <div class="d-flex align-center justify-center">
    <div id="cube-intro" class="cube-wrapper">
      <CubeCanvas
        v-if="isMounted"
        @adjustLogo="adjustLogo"
        @adjustWrapperMargin="adjustWrapperMargin"
        :textRect="textRect"
        :objDetails="details"
        :sec="sec"
      />
    </div>

    <v-row class="h-100" :id="wrapId">
      <v-col class="d-flex h-100 align-center" sm="8" md="5" data-speed="1.1">
        <h1 class="space-block" :id="textId">
          <div id="intro-text-1">
            <span class="super"> We are <span class="bold">ULO</span> </span
            ><br />
            <span class="opac-text">( unidentified landed object )</span>
          </div>
          <br />
          <div id="intro-text-2">
            <div>
              We are an innovation agency at the intersection of culture &amp;
              creativity.
            </div>
            <br />
            <div>We believe in innovation as an art form.</div>
          </div>
          <br />
          <div id="intro-text-3">
            <div>
              We help top global brands create multi-channel campaigns that are
              smart, strategic and stylish in their expression.
            </div>
            <br />
            <div></div>
            We ignite customer affinity and drive brands forward by leveraging
            emerging technologies (AI, Web3, immersive experiential) to ensure
            business vitality into the future.
          </div>
        </h1>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CubeCanvas from "./CubeCanvas.vue";
export default {
  name: "IntroSection",

  components: {
    CubeCanvas,
  },

  data: () => ({
    isMounted: false,
    sec: "intro",
    details: {
      circle: false,
      strokeWidth: 3,
      shapeWidth: 100,
      shapeHeight: 10,
      gradStroke: true,
      strokeColor: "#fff",
      gapBetweenShapes: 150,
      offsetMultiplier: 0.1,
      gradFill: false,
      fill: false,
      bg: "#000",
      gradColors: [
        [221, 116, 202],
        [157, 177, 255],
      ],
    },
    textRect: {
      top: 0,
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      width: 0,
      x: 0,
      y: 0,
    },
  }),
  mounted() {
    this.getTextRect();
    this.isMounted = true;
  },
  computed: {
    textId() {
      return "textSec-" + this.sec;
    },
    wrapId() {
      return "wrapSec-" + this.sec;
    },
  },
  methods: {
    getTextRect() {
      let dr = document
        .querySelector("#" + this.textId)
        ?.getBoundingClientRect();
      if (dr) {
        this.textRect = {
          top: dr.top,
          bottom: dr.bottom,
          height: dr.height,
          left: dr.left,
          right: dr.right,
          width: dr.width,
          x: dr.x,
          y: dr.y,
        };
      }
    },
    adjustWrapperMargin(margin) {
      const textWrapper = document.querySelector("#" + this.wrapId);
      textWrapper.style.marginLeft = margin;
      textWrapper.style.marginRight = margin;
    },

    adjustLogo(logoAdjustments) {
      const logo = document.querySelector("#logoImg");
      logo.style.maxWidth = logoAdjustments.mw;
      logo.style.marginBottom = logoAdjustments.mb;
    },
  },
};
</script>
