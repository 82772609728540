<template>
    <div class="card-wrapper" id="loadWrapper">
        <div id="card-inner-wrapper">
            <div id="card-inner-wrapper-2">
                <div class="card" id="loadCard">
                    <img id="loadLogo" class="logo" src="../assets/img/ulo_logo_full_white_transparent.png" alt=""
                        width="100%" />
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'LoadScreen',

}
</script>
<style>
@property --rotate {
    syntax: "<angle>";
    initial-value: 132deg;
    inherits: false;
}

:root {
    --card-height: 20rem;
    --card-width: 20rem;
}

.card-wrapper {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: #1a1a1a;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.logo {
    width: 15rem;
    opacity: 0.9;
}

.card {
    background: #1a1a1a;
    width: var(--card-width);
    height: var(--card-height);
    padding: 3px;
    position: relative;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    font-size: 1.5em;
    color: rgb(88 199 250 / 0%);
    font-family: cursive;
}

.card::before {
    content: "";
    width: 101%;
    height: 101%;
    border-radius: 100%;
    background-image: linear-gradient(var(--rotate), #FFAE7F, #9DB1FF 43%, #DD74CA);
    position: absolute;
    filter: grayscale(100%);

    z-index: -1;
    animation: spin 2.5s linear infinite;
}

.card::after {
    position: absolute;
    content: "";
    top: calc(var(--card-height) / 6);
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    transform: scale(0.7);
    height: 100%;
    width: 100%;
    margin: 0 auto;
    filter: blur(calc(var(--card-height) / 6));
    background-image: linear-gradient(var(--rotate), #DD74CA, #9DB1FF 43%, #1a1a1a);
    opacity: 1;
    transition: opacity .5s;
    animation: spin 2.5s linear infinite;
}

@keyframes spin {
    0% {
        --rotate: 0deg;
    }

    100% {
        --rotate: 360deg;
    }
}
</style>