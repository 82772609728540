<template>
    <canvas class="shape-canvas" :id="canvId"></canvas>
</template>
<script>
export default {
    name: 'CubeCanvas',

    components: {

    },

    props: {
        sec: {
            type: String,
            required: true
        },
        objDetails: {
            type: Object,
            required: true
        },
        textRect: {
            type: Object,
            required: false,
        },
    },
    computed: {
        mobileMultiplier() {
            return Math.max(window.innerWidth, window.innerHeight) / 2560
        },
        details() {
            var detailsObj = {};
            for (let d in this.objDetails) {
                if (typeof this.objDetails[d] == 'number') {
                    detailsObj[d] = this.objDetails[d] * this.mobileMultiplier
                } else {
                    detailsObj[d] = this.objDetails[d]
                }
            }
            return detailsObj
        },

        maxOffset() {
            return this.details.gapBetweenShapes * 0.4
        },
        canvId() {
            return 'cubeCanv-' + this.sec
        }
    },

    data: () => ({
        io: 0,
        ioy: 0,
        logoAdjustments: {},
    }),

    methods: {
        adjustWrapperMargin() {
            this.$emit('adjustWrapperMargin', this.io + 'px')
        },
        adjustLogo() {
            this.$emit('adjustLogo', this.logoAdjustments)
        }
    },

    mounted() {
        var details = this.details;
        var sec = this.sec;
        var textRect = this.textRect;
        var maxOffset = this.maxOffset;
        var htmlCanvas = document.getElementById(this.canvId);
        var ctx = htmlCanvas.getContext("2d");
        var globNC, globNR;
        var currentMouseX = 0;
        var currentMouseY = 0;
        var canvBorder = window.innerHeight * 0.04;

        ctx.fillStyle = "blue";
        ctx.fillRect(0, 0, htmlCanvas.width, htmlCanvas.height);



        var io = function () {
            let numCols = globNC;
            return (htmlCanvas.width - numCols * details.shapeWidth - (numCols - 1) * details.gapBetweenShapes) / 2;
        }

        var ioy = function () {
            let numRows = globNR
            return (htmlCanvas.height - numRows * details.shapeHeight - (numRows - 1) * details.gapBetweenShapes) / 2;
        }



        function initLogoLinks() {
            let maxLogoWidth = details.shapeWidth * 3 + details.gapBetweenShapes * 2.5 + - 10 + 'px'
            // if (window.innerHeight > window.innerWidth) {
            //     logo.style.marginLeft = io() + "px";
            //     logo.style.paddingLeft = "0px";
            //     logo.style.paddingRight = "0px";
            // }

            return {
                mw: maxLogoWidth,
                mb: ioy() + details.shapeHeight / 2 - canvBorder + "px"
            }
        }

        initialize()
        this.io = io();

        this.ioy = ioy();
        if (window.innerWidth >= 800 || sec == "landing") {
            this.adjustWrapperMargin()

        }
        if (sec == 'landing') {
            this.logoAdjustments = initLogoLinks();
            this.adjustLogo()
        }

        function initialize() {
            // window.addEventListener('resize', initialize, false);
            if (window.innerWidth > 800) {
                ['touchstart', 'touchmove', 'touchend', 'mousemove'].forEach(function (e) {
                    window.addEventListener(e, movementHandler.bind(this), false);
                });
            }
            function movementHandler(e) {
                if (e.type == 'touchstart' || e.type == 'touchmove' || e.type == 'touchend') {
                    var evt = (typeof e.originalEvent === 'undefined') ? e : e.originalEvent;
                    var touch = evt.touches[0] || evt.changedTouches[0];
                    currentMouseX = touch.pageX;
                    currentMouseY = touch.pageY;
                    redraw();
                } else if (e.type == 'mousemove') {
                    currentMouseX = e.clientX - window.innerHeight * 0.04;
                    currentMouseY = e.clientY - window.innerHeight * 0.04;
                    redraw();
                }
            }

            currentMouseX = window.innerWidth / 2;
            currentMouseY = window.innerHeight / 2;

            resizeCanvas();
        }


        function redraw() {
            ctx.clearRect(0, 0, htmlCanvas.width, htmlCanvas.height);

            var cx = htmlCanvas.width / 2;
            var cy = htmlCanvas.height / 2;

            let numCols = Math.floor((cx - (details.shapeWidth / 2)) / (details.gapBetweenShapes + details.shapeWidth)) * 2 + 1;
            let numRows = Math.floor((cy - (details.shapeHeight / 2)) / (details.gapBetweenShapes + details.shapeHeight)) * 2 + 1;

            globNC = numCols
            globNR = numRows

            let initialOffsetFromLeft = (htmlCanvas.width - numCols * details.shapeWidth - (numCols - 1) * details.gapBetweenShapes) / 2;
            let initialOffsetFromTop = (htmlCanvas.height - numRows * details.shapeHeight - (numRows - 1) * details.gapBetweenShapes) / 2;

            let circleOffset = details.shapeWidth / 2;

            for (let rowNum = 0; rowNum < numRows; rowNum++) {
                for (let colNum = 0; colNum < numCols; colNum++) {

                    let x1 = initialOffsetFromLeft + (colNum * (details.shapeWidth + details.gapBetweenShapes));
                    let y1 = initialOffsetFromTop + (rowNum * (details.shapeHeight + details.gapBetweenShapes));
                    if (!isTextIntersectingBlock(x1, y1, colNum, rowNum, numCols, numRows)) {
                        if (details.circle) {
                            drawCirclePair(x1 + circleOffset, y1 + circleOffset, rowNum, colNum)
                        } else {
                            drawRectanglePair(x1, y1, rowNum, colNum);
                        }
                    }
                }
            }

        }

        function resizeCanvas() {
            htmlCanvas.width = window.innerWidth - (.08 * window.innerHeight);
            htmlCanvas.height = window.innerHeight - (.08 * window.innerHeight);
            redraw();
        }


        function drawRectangle(x1, y1, row, col) {
            ctx.beginPath();

            ctx.rect(x1, y1, details.shapeWidth, details.shapeHeight);


            ctx.lineWidth = details.strokeWidth;
            if (details.gradStroke) {
                let rgb = getGradientColorFromPosition(row, col)
                ctx.strokeStyle = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]}`;
            } else {
                ctx.strokeStyle = details.strokeColor;
            }
            ctx.stroke();
        }

        function drawRectanglePair(f_x1, f_y1, row, col) {
            let xOffset = (currentMouseX - details.shapeWidth / 2 - f_x1) * details.offsetMultiplier,
                yOffset = (currentMouseY - details.shapeHeight / 2 - f_y1) * details.offsetMultiplier;

            xOffset = (Math.abs(xOffset) > maxOffset) ? (Math.sign(xOffset) * maxOffset) : xOffset;
            yOffset = (Math.abs(yOffset) > maxOffset) ? (Math.sign(yOffset) * maxOffset) : yOffset;
            drawRectangle(f_x1, f_y1, row, col);
            drawRectangle(f_x1 + xOffset, f_y1 + yOffset, row, col);

            let f_corners = [
                [f_x1, f_y1],
                [f_x1 + details.shapeWidth, f_y1],
                [f_x1 + details.shapeWidth, f_y1 + details.shapeHeight],
                [f_x1, f_y1 + details.shapeHeight]
            ];

            f_corners.forEach(drawLineToOffsetCorner);

            function drawLineToOffsetCorner(cornerPair) {
                let startx = cornerPair[0],
                    starty = cornerPair[1];
                ctx.beginPath();
                ctx.moveTo(startx, starty);
                ctx.lineTo(startx + xOffset, starty + yOffset);
                ctx.stroke();
            }

        }

        function getGradientColorFromPosition(row, col) {
            let r1 = details.gradColors[0][0];
            let r2 = details.gradColors[1][0];
            // let r3 = 157;
            // let r4 = 114
            let g1 = details.gradColors[0][1];
            let g2 = details.gradColors[1][1];
            // let g3 = 177;
            // let g4 = 255
            let b1 = details.gradColors[0][2];
            let b2 = details.gradColors[1][2];
            // let b3 = 255;
            // let b4 = 127

            let colIncR = (r2 - r1) / globNC
            let colIncG = (g2 - g1) / globNC
            let colIncB = (b2 - b1) / globNC
            let rowIncR = (r2 - r1) / globNR
            let rowIncG = (g2 - g1) / globNR
            let rowIncB = (b2 - b1) / globNR

            return ([((r1 + rowIncR * row) + (r1 + colIncR * col)) / 2, ((g1 + rowIncG * row) + (g1 + colIncG * col)) / 2, ((b1 + rowIncB * row) + (b1 + colIncB * col)) / 2])

        }


        function drawCircle(x1, y1, row, col, gradCircle = false) {
            ctx.beginPath();
            ctx.arc(x1, y1, details.shapeWidth / 2, 0, 2 * Math.PI);
            ctx.strokeStyle = details.strokeColor;
            ctx.lineWidth = details.strokeWidth;
            if (details.gradFill && gradCircle) {
                let rgb = getGradientColorFromPosition(row, col)
                ctx.fillStyle = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]}`;
                ctx.fill();
            } else if (details.fill) {
                ctx.fillStyle = details.strokeColor
                ctx.fill();
            }

            ctx.stroke();
        }

        function drawCirclePair(f_x1, f_y1, row, col) {
            let xOffset = (currentMouseX - details.shapeWidth / 2 - f_x1) * details.offsetMultiplier,
                yOffset = (currentMouseY - details.shapeHeight / 2 - f_y1) * details.offsetMultiplier;

            xOffset = (Math.abs(xOffset) > maxOffset) ? (Math.sign(xOffset) * maxOffset) : xOffset;
            yOffset = (Math.abs(yOffset) > maxOffset) ? (Math.sign(yOffset) * maxOffset) : yOffset;

            drawCircle(f_x1, f_y1);
            drawCircle(f_x1 + xOffset, f_y1 + yOffset, row, col, true);

        }






        function isTextIntersectingBlock(x, y, col, row, numCols, numRows) {
            if (sec == 'landing') {
                // if (row == 0 && (col == 0 || col == 1)) {
                //     return true;
                // }
                if (row == numRows - 1 && (col >= numCols - 3)) {
                    return true;
                }

                return false;
            } else if (sec == 'subscribe' && window.innerWidth <= 576) {
                let endSecHeight = textRect.bottom - (window.innerHeight * 0.04)
                var blockOutRows = Math.ceil((endSecHeight / htmlCanvas.height) * numRows)
                if (row < blockOutRows) {
                    return true;
                }
                return false;
            }

            let x1 = x - details.gapBetweenShapes / 2;
            let x2 = x + details.shapeWidth + details.gapBetweenShapes / 2;
            let y1 = y - details.gapBetweenShapes / 2;
            let y2 = y + details.shapeHeight + details.gapBetweenShapes / 2;

            let top = window.innerHeight / 2 - textRect.height / 2
            let bottom = window.innerHeight / 2 + textRect.height / 2
            let left = textRect.left
            let right = textRect.right

            return !(right - canvBorder <= x1 ||
                bottom - canvBorder <= y1 ||
                left - canvBorder >= x2 ||
                top - canvBorder >= y2)


        }

    }
};
</script>