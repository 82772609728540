<template>
  <div class="announcement-bar bold" v-if="showBar">
    <div class="announcement-bar__content">
      <div class="announcement-bar__content__text">
        <div class="mw-80">
          <div
            class="pink-text announcement-text"
            :class="isMobile ? 'py-1 mr-2' : 'py-1 '"
          >
            Our latest activation with NARS COSMETICS:
            <a
              class="pink-text"
              href="https://www.forbes.com/sites/stephaniehirschmiller/2023/07/31/how-nars-hybrid-nft--ar-orgasm-activated-campaign-is-bringing-web3-to-a-wider-audience/?sh=2198a69070f4"
              target="_blank"
              >ORGASM, ACTIVATED</a
            >
          </div>
          <span v-if="!isMobile" class="separator">|</span>
          <br v-if="isMobile" />
          <div
            class="announcement-text"
            :class="isMobile ? 'py-1 mr-2' : 'py-1 '"
          >
            <!-- <SignupDialog v-if="openSignupDialog" @closeDialog="closeDialog"></SignupDialog> -->
            <a
              href="https://artistandthemachine.beehiiv.com/subscribe"
              target="_blank"
              >SIGN UP FOR OUR AI & GENERATIVE ART NEWSLETTER
            </a>
          </div>
          <span v-if="!isMobile" class="separator">|</span>

          <div class="announcement-text" :class="isMobile ? 'py-1' : 'py-1 '">
            <a class="no-ul" href="mailto:founders@ulo.world">contact us</a>
          </div>
        </div>
      </div>
      <div class="close" v-if="!openSignupDialog">
        <v-btn icon @click="closeBar">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
// import SignupDialog from "@/components/SignupDialog.vue";
export default {
  components: {
    // SignupDialog,
  },
  name: "AnnouncementBar",
  data: () => ({
    showBar: true,
    openSignupDialog: false,
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    closeBar() {
      this.showBar = false;
    },
    openDialog() {
      this.openSignupDialog = true;
    },
    closeDialog() {
      this.openSignupDialog = false;
      console.log("2");
    },
  },
};
</script>

<style scoped>
.announcement-bar__content {
  max-width: 80%;
}

.announcement-bar {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: auto;
  padding-block: 10px;
  background-color: rgba(26, 26, 26, 0.9);
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.announcement-text {
  display: inline-block;
}

@media (max-width: 768px) {
  .announcement-bar {
    font-size: 10px;
    line-height: 14px;
    height: auto;
    padding: 10px 0;
  }

  .announcement-text {
    display: block;
    padding-inline: 20px;
  }
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
}

.separator {
  margin: 0 20px;
}

.pink-text {
  color: #dd74ca !important;
}

a {
  text-decoration: underline;
}
</style>
