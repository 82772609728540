<template>
  <AppWrapper :key="refreshComponent" :load="loadScreen"></AppWrapper>
</template>

<script>
import AppWrapper from './components/AppWrapper.vue'
export default {
  name: 'App',
  metaInfo: {
    title: 'ULO - Unidentified Landed Object',
    description: 'A Web3 strategy agency and experience studio.'
  },

  components: {
    AppWrapper
  },

  data: () => ({
    refreshComponent: 0,
    loadScreen: true,
  }),

  mounted() {
    if (Math.max(window.innerHeight, window.innerWidth) > 1180)
      window.addEventListener('resize', this.forceRefresh);
  },

  methods: {
    forceRefresh() {
      this.loadScreen = false;
      this.refreshComponent += 1;
    },
  }
}
</script>

<style>
@font-face {
  font-family: "Trade-GothicLT";
  src: local("Trade-GothicLT"),
    url(./assets/fonts/Trade-gothic-lt-regular.otf) format("opentype");
}

@font-face {
  font-family: "Trade-GothicLT-bold";
  src: local("Trade-GothicLT-bold"),
    url(./assets/fonts/Trade-gothic-lt-bold.otf) format("opentype");
}

@font-face {
  font-family: "Trade-GothicLT-light";
  src: local("Trade-GothicLT-light"),
    url(./assets/fonts/Trade-gothic-std-light.otf) format("opentype");
}

.v-application {
  background-color: "#1a1a1a";
  cursor: none !important;
  font-family: "Trade-GothicLT", sans-serif;
  /* text-transform: lowercase; */
  color: rgba(255, 255, 255, 0.85) !important;
}

.bold {
  font-family: "Trade-GothicLT-bold"
}

h1 {
  font-family: "Trade-GothicLT-light"
}

/* .super {
  font-family: "Trade-GothicLT"
} */

@import './css/main.css';
</style>