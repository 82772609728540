<template>
    <div>
        <div id="cube-landing" class="cube-wrapper">
            <CubeCanvas @adjustLogo="adjustLogo" @adjustWrapperMargin="adjustWrapperMargin" :textRect="textRect"
                :objDetails="details" :sec="sec" />
        </div>

        <div class="h-100" :id="wrapId">
            <div class="d-flex align-end justify-end mb-6 h-100">
                <div>
                    <img id="logoImg" src="../assets/img/ulo_logo_headling_white_transparent.png" alt="" width="100%" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CubeCanvas from './CubeCanvas.vue';
export default {
    name: 'LandingSection',

    components: {
        CubeCanvas
    },

    data: () => ({
        sec: 'landing',
        details: {
            circle: false,
            strokeWidth: 2,
            shapeWidth: 75,
            shapeHeight: 75,
            gradStroke: false,
            strokeColor: '#fff',
            gapBetweenShapes: 100,
            offsetMultiplier: 0.2,
            gradFill: false,
            fill: false,
        },
        textRect: {
            top: 0,
            bottom: 0,
            height: 0,
            left: 0,
            right: 0,
            width: 0,
            x: 0,
            y: 0
        }
    }),
    mounted() {
        this.getTextRect()
    },
    computed: {
        textId() {
            return 'textSec-' + this.sec;
        },
        wrapId() {
            return 'wrapSec-' + this.sec;
        },
    },
    methods: {
        getTextRect() {
            let dr = document.querySelector(this.textId)?.getBoundingClientRect();
            if (dr) {
                this.textRect = {
                    top: dr.top,
                    bottom: dr.bottom,
                    height: dr.height,
                    left: dr.left,
                    right: dr.right,
                    width: dr.width,
                    x: dr.x,
                    y: dr.y
                }
            }

        },
        adjustWrapperMargin(margin) {
            const textWrapper = document.querySelector('#' + this.wrapId);
            textWrapper.style.marginLeft = margin;
            textWrapper.style.marginRight = margin;
        },

        adjustLogo(logoAdjustments) {
            const logo = document.querySelector("#logoImg")
            logo.style.maxWidth = logoAdjustments.mw
            logo.style.marginBottom = logoAdjustments.mb
        }
    }
}
</script>