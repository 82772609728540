<template>
    <canvas id="bgCanv"></canvas>
</template>
<script>
import Gradient from '../js/background'
export default {
    data: () => ({
        gradient: new Gradient()

    }),
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.gradient.initGradient("#bgCanv");
        },
        toggleColor(col) {
            var ret;
            if (col == 0 || col == 1 || col == 3) {
                if (col == 0)
                    ret = [0, 0, 0, 1]
                else if (col == 1)
                    ret = [0, 1, 0, 1]
                else if (col == 3)
                    ret = [1, 0, 0, 1]
                this.gradient.toggleColor(ret)

            }

        }
    }
}
</script>