<template>
  <v-app class="h-100 d-flex align-center body-white">
    <div class="curzr d-flex align-center justify-center" hidden>
      <div class="circle">
        <div class="scroll-text">
          <v-icon large>mdi-arrow-down</v-icon>
        </div>
      </div>
    </div>
    <div class="preload"></div>
    <div v-if="loading" class="load-comp-wrapper">
      <LoadScreen></LoadScreen>
    </div>
    <DynamicGradient ref="gradient" />
    <AnnouncementBar></AnnouncementBar>
    <div id="smooth-wrapper">
      <div id="smooth-content">
        <div class="scroll-panel no-pad" id="landingSection">
          <LandingSection class="content" />
        </div>
        <div class="scroll-panel" id="introSection">
          <IntroSection class="content" />
        </div>
        <!-- <div class="scroll-panel" id="foundersSection">
                    <FoundersSection class="max-w" />
                </div> -->
        <div class="scroll-panel" id="servicesSection">
          <ServicesSection class="content" />
        </div>
        <!-- <div class="scroll-panel" id="aboutSection-1">
                    <AboutSection1 class="content" />
                </div> -->

        <div class="scroll-panel" id="previousSection">
          <PreviousWorkSection class="content" />
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import AnnouncementBar from "./AnnouncementBar.vue";
import LandingSection from "./LandingSection.vue";
import IntroSection from "./IntroSection.vue";
import ServicesSection from "./ServicesSection.vue";
// import AboutSection1 from './AboutSection1.vue';
// import AboutSection2 from './components/AboutSection2.vue';
// import FoundersSection from "./FoundersSection.vue";
import PreviousWorkSection from "./PreviousWorkSection.vue";
import LoadScreen from "./LoadScreen.vue";
// import SignUpSection from './components/SignUpSection.vue';
import DynamicGradient from "./DynamicGradient.vue";
import Vue from "vue";
import VueMeta from "vue-meta";

// import Scrollbar from 'smooth-scrollbar'

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";

Vue.use(VueMeta);

export default {
  components: {
    LandingSection,
    IntroSection,
    ServicesSection,
    // AboutSection1,f
    // AboutSection2,
    // FoundersSection,
    PreviousWorkSection,
    // SignUpSection,
    AnnouncementBar,
    DynamicGradient,
    LoadScreen,
  },

  data: () => ({
    refreshComponent: 0,
    activePanel: null,
    scrollerScrollTop: 0,
    currentActiveSec: 0,
    secPositionMap: {},
    endReached: false,
  }),
  props: {
    load: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 800;
    },
    loading() {
      return this.load;
    },
  },
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute(
      "src",
      "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=VZ8DqH"
    );
    plugin.async = true;
    document.head.appendChild(plugin);

    if (matchMedia("(pointer:fine)").matches) {
      this.initCurzr();
    } else {
      document.querySelectorAll(".scroll-panel").forEach((el) => {
        el.style.minHeight = window.innerHeight + "px";
      });
    }

    var actualSec = 0;
    var lastActualSec = 0;
    var refGrad = this.$refs.gradient;

    const sectionMap = {
      0: 0,
      1: 1,
      2: 1,
      3: 2,
      4: 2,
      5: 2,
      9: 3,
    };

    var $vm = this;
    var smoother;

    gsap.registerPlugin(ScrollTrigger);

    if (!this.isMobile) {
      gsap.registerPlugin(ScrollSmoother);

      smoother = ScrollSmoother.create({
        wrapper: "#smooth-wrapper",
        content: "#smooth-content",
        smooth: 1,
        smoothTouch: 0.1,
        effects: true,
      });
    }

    var scrollCircle = document.querySelector(".scroll-text");
    var timer = setTimeout(function () {
      scrollCircle.classList.add("throbbing");
    }, 8000);

    window.addEventListener("scroll", function () {
      var scroll;
      if ($vm.isMobile) {
        scroll = this.window.scrollY;
      } else {
        scroll = smoother.scrollTop();
        if (timer !== null) {
          clearTimeout(timer);
          scrollCircle.classList.remove("throbbing");
        }
        timer = setTimeout(function () {
          if (actualSec != 5) scrollCircle.classList.add("throbbing");
        }, 5000);
      }

      actualSec = sectionMap[Math.trunc(scroll / window.innerHeight)];

      if (actualSec != lastActualSec) {
        $vm.currentActiveSec = actualSec;
        lastActualSec = actualSec;
        refGrad.toggleColor(actualSec);
      }
    });

    this.gsapInit();
  },

  methods: {
    initCurzr() {
      class BigCircle {
        constructor() {
          this.root = document.body;
          this.cursor = document.querySelector(".curzr");
          this.circle = document.querySelector(".curzr .circle");
          this.lastId = "";

          this.pointerX = 0;
          this.pointerY = 0;
          this.cursorSize = 100;

          this.circleStyle = {
            boxSizing: "border-box",
            position: "fixed",
            top: `${this.cursorSize / -2}px`,
            left: `${this.cursorSize / -2}px`,
            zIndex: "100",
            width: `${this.cursorSize}px`,
            height: `${this.cursorSize}px`,
            backgroundColor: "#fff0",
            borderRadius: "50%",
            transition: "500ms, transform 100ms",
            userSelect: "none",
            pointerEvents: "none",
          };

          if (CSS.supports("backdrop-filter", "invert(1) grayscale(1)")) {
            this.circleStyle.backdropFilter = "invert(1) grayscale(1)";
            this.circleStyle.backgroundColor = "#fff0";
          } else {
            this.circleStyle.backgroundColor = "#000";
            this.circleStyle.opacity = "0.75";
          }

          this.init(this.circle, this.circleStyle);
        }

        init(el, style) {
          Object.assign(el.style, style);
          this.cursor.removeAttribute("hidden");
        }

        move(event) {
          this.pointerX = event.pageX;
          this.pointerY = event.pageY + this.root.getBoundingClientRect().y;
          this.circle.style.transform = `translate3d(${this.pointerX}px, ${this.pointerY}px, 0)`;

          if (event.target.id == "img1" || event.target.id == "img2") {
            this.rmInvert();
          } else if (this.lastId == "img1" || this.lastId == "img2") {
            this.addInvert();
          }

          if (
            event.target.localName === "button" ||
            event.target.localName === "a" ||
            event.target.onclick !== null ||
            event.target.className.includes("curzr-hover")
          ) {
            this.hover();
          }
          this.lastId = event.target.id;
        }

        hover() {
          this.circle.style.transform += ` scale(1.5)`;
        }

        click() {
          this.circle.style.transform += ` scale(0.75)`;
          setTimeout(() => {
            this.circle.style.transform = this.circle.style.transform.replace(
              ` scale(0.75)`,
              ""
            );
          }, 35);
        }

        remove() {
          this.circle.remove();
          this.dot.remove();
        }

        rmInvert() {
          if (CSS.supports("backdrop-filter", "invert(1) grayscale(1)")) {
            this.circle.style.backdropFilter = "grayscale(1)";
            this.circle.style.border = "2px solid white";
          }
        }

        addInvert() {
          if (CSS.supports("backdrop-filter", "invert(1) grayscale(1)")) {
            this.circle.style.backdropFilter = "invert(1) grayscale(1)";
            this.circle.style.border = "none";
          }
        }
      }

      (() => {
        const cursor = new BigCircle();
        if (
          !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          )
        ) {
          document.onmousemove = function (event) {
            cursor.move(event);
          };
          document.onclick = function () {
            cursor.click();
          };
        } else {
          cursor.remove();
        }
      })();
    },

    gsapInit() {
      let vh = window.innerHeight;
      const vw = window.innerWidth;
      let carousel_width_1 =
        document.querySelector("#carousel-text-1").scrollWidth + 10;
      let carousel_width_2 =
        document.querySelector("#carousel-text-2").scrollWidth + 10;

      const tl_load = gsap.timeline({ defaults: { ease: "Expo.easeInOut" } });

      if (this.loading) {
        tl_load
          .from("#card-inner-wrapper", {
            filter: "blur(10px)",
            opacity: 0,
            duration: 1.5,
          })
          .from(
            "#loadLogo",
            { opacity: 0, filter: "blur(10px)", duration: 1.5 },
            "-=1"
          )
          .to("#card-inner-wrapper-2", {
            y: "-200px",
            duration: 1,
            delay: 1,
            ease: "none",
          })
          .to(
            "#loadWrapper",
            { y: "-" + window.innerHeight + "px", duration: 2 },
            "-=1"
          )

          .from(
            "#cube-landing",
            { y: "100px", opacity: 0, duration: 1, ease: "Power2.easeOut" },
            "-=1.5"
          );

        if (!this.isMobile) {
          tl_load.from(
            "#logoImg",
            { y: "150px", opacity: 0, duration: 1.2, ease: "Power2.easeOut" },
            "-=1.5"
          );
        }

        tl_load.to(".load-comp-wrapper", { display: "none" });
      }

      const tl_landing = gsap.timeline({
        scrollTrigger: {
          trigger: "#landingSection",
          start: "top top",
          end: "bottom top",
          scrub: 0.6,
          pin: true,
          pinSpacing: false,
          anticipatePin: 1,
          ease: "none",
        },
      });

      tl_landing
        .to("#landingSection", {
          filter: "blur(20px)",
          opacity: 0,
          duration: 3,
        })
        .to("#bgCanv", { filter: "grayscale(1)", duration: 3 }, "-=3");

      const tl_intro_bg = gsap.timeline({
        scrollTrigger: {
          trigger: "#introSection",
          start: "top top",
          end: "+=100%",
          scrub: 0.6,
          pin: true,
        },
      });

      tl_intro_bg
        .to("#cube-intro", {
          duration: 5,
          filter: "blur(20px)",
          opacity: 0,
          delay: 2,
        })
        .to(
          "#textSec-intro",
          {
            duration: 5,
            opacity: 0,
            color: "#000",
          },
          "-=5"
        )
        .to("#bgCanv", { filter: "grayscale(0)", duration: 3 }, "-=3");

      //INTRO TEXT IN, ONE AT A TIME
      const tl_intro = gsap.timeline({
        scrollTrigger: {
          trigger: "#introSection",
          start: "top bottom-=300px",
          end: "+=50%",
          scrub: 0.6,
        },
      });

      tl_intro
        .from("#intro-text-1", {
          duration: 1,
          x: "-800px",
          opacity: 0,
          filter: "blur(20px)",
        })
        .from("#intro-text-2", {
          duration: 1,
          x: "-800px",
          opacity: 0,
          filter: "blur(20px)",
        })
        .from("#intro-text-3", {
          duration: 1,
          x: "-800px",
          opacity: 0,
          filter: "blur(20px)",
        });

      const tl_services = gsap.timeline({
        scrollTrigger: {
          trigger: "#servicesSection",
          scrub: 0.6,
          start: "top top",
          end: "+=600%",
          pin: true,
          anticipatePin: true,
        },
      });

      tl_services
        .to("#services-text", { duration: 0.5, opacity: 0, delay: 1 })
        .to(
          "#services-text-2",
          { duration: 0.5, opacity: 0, delay: 1 },
          "-=1.5"
        )
        .fromTo(
          "#carousel-text-1",
          { x: carousel_width_1 + (this.isMobile ? 2.2 : 1.5) * vw + "px" },
          {
            duration: 50,
            x: "-" + carousel_width_1 + "px",
            scale: 1.2,
            opacity: 0.8,
          }
        )
        .fromTo(
          "#carousel-text-2",
          { x: -1 * (carousel_width_2 + (this.isMobile ? 2.2 : 1.5) * vw) },
          {
            duration: 50,
            x: "+" + (carousel_width_2 + 1.5 * vw) + "px",
            scale: 1.2,
            opacity: 0.8,
          },
          "-=50"
        )
        .to("#cube-services", { duration: 2, filter: "blur(20px)" }, "-=4")
        .to(
          "#servicesSection",
          { duration: 1.5, filter: "blur(20px)", opacity: 0, color: "#000" },
          "-=2"
        )
        .to("#bgCanv", { filter: "grayscale(1)", duration: 3 }, "-=1");

      const tl_partners_scroll_in = gsap.timeline({
        scrollTrigger: {
          trigger: "#previousSection",
          start: "top bottom",
          end: "top top",
          scrub: 0.6,
          ease: "none",
        },
      });

      tl_partners_scroll_in
        .from("#scroller-box-window", {
          y: -1 * vh + "px",
          scale: 0.6,
          opacity: 0,
          filter: "blur(10px)",
          duration: 5,
        })
        .from(
          "#previousTitle",
          { y: "350px", opacity: 0, filter: "blur(10px)", duration: 5 },
          "-=5"
        );

      this.getSecPositions();
    },

    getSecPositions() {
      let secs = gsap.utils.toArray(".pin-spacer");
      for (let i = 0; i < secs.length; i++) {
        let rect = secs[i].getBoundingClientRect();
        this.secPositionMap[i] = [rect.top, rect.bottom];
      }
    },
  },
};
</script>
